:root {
  --color-Type: #0d0d12;
  --color-BG-1: #ffffff;
  --color-BG-2: #f9f6f3;
  --color-BG-3: #f3ede7;
  --color-Action: #75003f;
  --color-Light-Gray: #b9bbcf;
  --color-Medium-Gray: #7c7f9d;
  --color-Dark-Gray: #4a5071;
  --color-Error: #c83d56;
  --color-Background: #f9f6f3;
  --color-UI: #7c7f9d;
}
