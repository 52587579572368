@font-face {
  font-family: 'Northwell';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Northwell.woff') format('woff'), url('/fonts/Northwell.woff2') format('woff2');
}

@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 200;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Thin.woff') format('woff'),
    url('/fonts/GT-Walsheim-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 200;
  font-style: oblique;
  src: url('/fonts/GT-Walsheim-Thin-Oblique.woff') format('woff'),
    url('/fonts/GT-Walsheim-Thin-Oblique.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Light.woff') format('woff'),
    url('/fonts/GT-Walsheim-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Regular.woff') format('woff'),
    url('/fonts/GT-Walsheim-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-style: oblique;
  src: url('/fonts/GT-Walsheim-Regular-Oblique.woff') format('woff'),
    url('/fonts/GT-Walsheim-Regular-Oblique.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Medium.woff') format('woff'),
    url('/fonts/GT-Walsheim-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-style: oblique;
  src: url('/fonts/GT-Walsheim-Medium-Oblique.woff') format('woff'),
    url('/fonts/GT-Walsheim-Medium-Oblique.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Sectra';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/GT-Sectra-Display-Regular.woff') format('woff'),
    url('/fonts/GT-Sectra-Display-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Sectra';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/GT-Sectra-Display-Light.woff') format('woff'),
    url('/fonts/GT-Sectra-Display-Light.woff2') format('woff2');
}
