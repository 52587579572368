@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*,
*::before,
*::after {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  line-height: 1.8;
  font-size: 15px;
  color: #06132a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.2;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}

:root {
  --color-Type: #0d0d12;
  --color-BG-1: #ffffff;
  --color-BG-2: #f9f6f3;
  --color-BG-3: #f3ede7;
  --color-Action: #75003f;
  --color-Light-Gray: #b9bbcf;
  --color-Medium-Gray: #7c7f9d;
  --color-Dark-Gray: #4a5071;
  --color-Error: #c83d56;
  --color-Background: #f9f6f3;
  --color-UI: #7c7f9d;
}

@font-face {
  font-family: 'Northwell';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Northwell.woff') format('woff'), url('/fonts/Northwell.woff2') format('woff2');
}

@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 200;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Thin.woff') format('woff'),
    url('/fonts/GT-Walsheim-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 200;
  font-style: oblique;
  src: url('/fonts/GT-Walsheim-Thin-Oblique.woff') format('woff'),
    url('/fonts/GT-Walsheim-Thin-Oblique.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Light.woff') format('woff'),
    url('/fonts/GT-Walsheim-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Regular.woff') format('woff'),
    url('/fonts/GT-Walsheim-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-style: oblique;
  src: url('/fonts/GT-Walsheim-Regular-Oblique.woff') format('woff'),
    url('/fonts/GT-Walsheim-Regular-Oblique.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/GT-Walsheim-Medium.woff') format('woff'),
    url('/fonts/GT-Walsheim-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-style: oblique;
  src: url('/fonts/GT-Walsheim-Medium-Oblique.woff') format('woff'),
    url('/fonts/GT-Walsheim-Medium-Oblique.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Sectra';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/GT-Sectra-Display-Regular.woff') format('woff'),
    url('/fonts/GT-Sectra-Display-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'GT-Sectra';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/GT-Sectra-Display-Light.woff') format('woff'),
    url('/fonts/GT-Sectra-Display-Light.woff2') format('woff2');
}

